import React from 'react';
import PropTypes from 'prop-types';

const AUBachelorFilter = ({
  filterName,
  filterLabel,
  renderItems,
  visible,
  selected,
  show,
  hide,
  clear,
  right,
}) => (
  <div className="bachelor__education__filter">
    <button
      type="button"
      className={`bachelor__education__filter__button${selected ? ' bachelor__education__filter__button--selected' : ''}`}
      onClick={() => {
        if (selected) {
          clear();
        } else {
          show();
        }
      }}
    >
      {filterName}
    </button>
    <div className={`bachelor__education__filter__list${visible ? ' bachelor__education__filter__list--visible' : ''}${right ? ' bachelor__education__filter__list--right' : ''}`}>
      <span className="bachelor__education__filter__list__select">{filterLabel}</span>
      <button
        type="button"
        className="bachelor__education__filter__list__close"
        onClick={() => {
          hide();
        }}
      >
        c
      </button>
      <ul className="resetlist">
        {renderItems}
      </ul>
    </div>
  </div>
);

AUBachelorFilter.defaultProps = {
  right: false,
};

AUBachelorFilter.propTypes = {
  filterName: PropTypes.string.isRequired,
  filterLabel: PropTypes.string.isRequired,
  renderItems: PropTypes.arrayOf(PropTypes.element).isRequired,
  visible: PropTypes.bool.isRequired,
  show: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  right: PropTypes.bool,
};

AUBachelorFilter.displayName = 'AUBachelorFilter';
export default AUBachelorFilter;
