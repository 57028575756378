/* eslint react/no-danger: 0 */
import React, { useContext } from 'react';
import { modalContentNowWhatContext } from '../helpers/hooks';

const AUBachelorJourneyModalNowWhat = () => {
  const content = useContext(modalContentNowWhatContext);
  return (
    <div className="modal-view__body">
      <div className="bachelor__journey" dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

AUBachelorJourneyModalNowWhat.displayName = 'AUBachelorJourneyModalNowWhat';
export default AUBachelorJourneyModalNowWhat;
