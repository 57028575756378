import {
  GET_ITEM,
  GET_EDUCATIONS,
  SET_MODAL,
  SET_MODAL_FILTERS,
} from '../actions/const';
import { letters } from '../i18n';

const initialState = {
  loaded: false,
  item: null,
  list: {
    Interests: [],
    Locations: [],
    Faculties: [],
    Languages: [],
    Items: [],
  },
  modal: null,
  filteredItems: [],
  globalFilters: {
    languages: [],
    locations: [],
    faculties: [],
    letters: [letters[0]],
    alphabet: '',
  },
};

const reducer = (state = initialState, action) => {
  const nextState = { ...state };

  switch (action.type) {
    case GET_EDUCATIONS: {
      nextState.list = action.result;
      nextState.filteredItems = action.result.Items;
      nextState.loaded = true;
      return nextState;
    }
    case GET_ITEM: {
      nextState.item = action.result;
      return nextState;
    }
    case SET_MODAL: {
      nextState.modal = action.result;
      if (action.result) {
        nextState.filteredItems = action.result.Items;
      }
      return nextState;
    }
    case SET_MODAL_FILTERS: {
      nextState.globalFilters = action.result;
      const {
        globalFilters: {
          languages,
          locations,
          faculties,
          letters: filterLetters,
          alphabet,
        },
        list,
        modal,
      } = nextState;

      let { Items } = list;
      let filteredItems = [...Items];
      if (modal != null) {
        Items = modal.Items;
        filteredItems = [...modal.Items];
      }

      if (languages.length > 0 || locations.length > 0 || faculties.length > 0 || alphabet !== '') {
        const newItems = [];
        Items.forEach((item) => {
          let found = true;
          if (alphabet !== '') {
            if (item.Name.toLowerCase().indexOf(alphabet) !== 0) {
              found = false;
            }
          }

          let langFound = languages.length === 0;
          languages.forEach((l) => {
            if (item.Language && item.Language.includes(l.Name)) {
              langFound = true;
            }
          });

          if (!langFound) {
            found = false;
          }

          let locFound = locations.length === 0;
          locations.forEach((l) => {
            if (item.Location && item.Location.includes(l.Name)) {
              locFound = true;
            }
          });

          if (!locFound) {
            found = false;
          }

          let facFound = faculties.length === 0;
          faculties.forEach((f) => {
            if (item.Faculty && item.Faculty.includes(f.Name)) {
              facFound = true;
            }
          });

          if (!facFound) {
            found = false;
          }

          if (found) {
            newItems.push(item);
          }
        });

        filteredItems = newItems;
      }

      filteredItems.sort((a, b) => {
        if (filterLetters[0].ID === 'asc') {
          return a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 : -1;
        }
        return a.Name.toLowerCase() > b.Name.toLowerCase() ? -1 : 1;
      });
      nextState.filteredItems = filteredItems;
      return nextState;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
