/* eslint react/no-danger: 0 */
import React, { useContext } from 'react';
import { modalContentDoubtContext } from '../helpers/hooks';

const AUBachelorJourneyModalDoubt = () => {
  const content = useContext(modalContentDoubtContext);
  return (
    <div className="modal-view__body">
      <div className="bachelor__journey" dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

AUBachelorJourneyModalDoubt.displayName = 'AUBachelorJourneyModalDoubt';
export default AUBachelorJourneyModalDoubt;
