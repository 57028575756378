/* eslint-env browser */
import React, { useState, useLayoutEffect } from 'react';

const lang = document.querySelector('html').getAttribute('lang');
const langContext = React.createContext(lang);
const modalContentForYou = document.getElementById('bachelor__journey__modal-content__1')?.innerHTML;
const modalContentForYouContext = React.createContext(modalContentForYou);
const modalContentDoubt = document.getElementById('bachelor__journey__modal-content__2')?.innerHTML;
const modalContentDoubtContext = React.createContext(modalContentDoubt);
const modalContentAdmission = document.getElementById('bachelor__journey__modal-content__3')?.innerHTML;
const modalContentAdmissionContext = React.createContext(modalContentAdmission);
const modalContentNowWhat = document.getElementById('bachelor__journey__modal-content__4')?.innerHTML;
const modalContentNowWhatContext = React.createContext(modalContentNowWhat);

const emptyFilter = {
  languages: false,
  locations: false,
  faculties: false,
  letters: false,
};

const useFilters = (
  globalFilters,
  setGlobalFilters,
) => {
  const [filters, setFilters] = useState({
    languages: false,
    locations: false,
    faculties: false,
    letters: false,
  });

  const renderCollection = (collection = [], key) => collection.map((item) => (
    <li key={item.ID}>
      <button
        type="button"
        className={globalFilters[key].find((x) => x.ID === item.ID) ? 'item-selected' : null}
        onClick={() => {
          setGlobalFilters({ ...globalFilters, [key]: [item] });
          setFilters(emptyFilter);
        }}
      >
        <span>{item.Name}</span>
      </button>
    </li>
  ));

  return [filters, setFilters, renderCollection];
};

const useSVGHover = (selector) => {
  useLayoutEffect(() => {
    const svgElements = document.querySelectorAll(selector);
    svgElements.forEach((svgElement) => {
      svgElement.addEventListener('mouseover', () => {
        svgElements.forEach((hoverElement) => {
          hoverElement.classList.add('journey-hover');
          if (hoverElement.classList.contains('text')) {
            hoverElement.classList.add('journey-hover-text');
          } else if (hoverElement.classList.contains('icon')) {
            hoverElement.classList.add('journey-hover-icon');
          } else {
            hoverElement.classList.add('journey-hover-box');
          }
        });
      });
      svgElement.addEventListener('mouseout', () => {
        svgElements.forEach((hoverElement) => {
          hoverElement.classList.remove('journey-hover');
          hoverElement.classList.remove('journey-hover-box');
          hoverElement.classList.remove('journey-hover-text');
          hoverElement.classList.remove('journey-hover-icon');
        });
      });
    });
  }, []);
};

export {
  useFilters,
  emptyFilter,
  useSVGHover,
  langContext,
  modalContentForYouContext,
  modalContentDoubtContext,
  modalContentAdmissionContext,
  modalContentNowWhatContext,
};
