/* eslint-disable max-len */
/* eslint-env browser */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import AutoSuggestComponent from '@aarhus-university/au-lib-react-components/src/components/AutoSuggestComponent';
import { sortAlphaObj } from '@aarhus-university/au-lib-react-components/src/lib/helpers';
import { langContext } from '../helpers/hooks';
import { labels } from '../i18n';

const getSuggestions = (value, collection, callback) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  let result = [];
  if (inputLength >= 2) {
    result = collection.filter((x) => x.Name.toLowerCase().indexOf(inputValue) > -1).sort(sortAlphaObj);
    if (result.length === 0 && inputValue === 'online') {
      result = collection.filter((x) => x.Online).sort(sortAlphaObj);
    }
  }
  callback(result);
};

const AUBachelorSearch = ({ items, centered }) => {
  const lang = useContext(langContext);
  const mastersMode = window.endpoint.includes('masters');

  return (
    <div className={`bachelor__education__search${centered ? ' bachelor__education__search--centered' : ''}`}>
      <AutoSuggestComponent
        placeholder={mastersMode ? labels.searchHeaderMasters[lang] : labels.searchHeader[lang]}
        setQuery={() => { }}
        setResults={(results) => {
          if (results.length === 1 && results[0].Uri) {
            window.location.href = results[0].Uri;
          }
        }}
        collection={items}
        getSuggestions={getSuggestions}
        getSuggestionValue={(suggestion) => suggestion.Name}
        renderSuggestion={(suggestion) => {
          const online = !suggestion.Online ? null : <span className="bachelor__education__search__item__note">{suggestion.Online}</span>;
          return (
            <div className="bachelor__education__search__item">
              {suggestion.Name}
              {online}
            </div>
          );
        }}
      />
    </div>
  );
};

AUBachelorSearch.defaultProps = {
  centered: false,
};

AUBachelorSearch.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  centered: PropTypes.bool,
};

AUBachelorSearch.displayName = 'AUBachelorSearch';
export default AUBachelorSearch;
