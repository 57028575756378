/* eslint react/no-danger: 0 */
import React, { useContext } from 'react';
import { modalContentAdmissionContext } from '../helpers/hooks';

const AUBachelorJourneyModalAdmission = () => {
  const content = useContext(modalContentAdmissionContext);
  return (
    <div className="modal-view__body">
      <div className="bachelor__journey" dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

AUBachelorJourneyModalAdmission.displayName = 'AUBachelorJourneyModalAdmission';
export default AUBachelorJourneyModalAdmission;
