/* eslint-env browser */
/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AUSpinnerComponent from '@aarhus-university/au-lib-react-components/src/components/delphinus/AUSpinnerComponent';
import AUBachelorFilter from './AUBachelorFilter';
import AUBachelorSearch from './AUBachelorSearch';
import AUBachelorAlphabet from './AUBachelorAlphabet';
import actionCreators from '../actions/bachelor';
import { useFilters, emptyFilter, langContext } from '../helpers/hooks';
import { letters, labels } from '../i18n';

const AUBachelorList = ({
  loaded,
  list,
  filteredItems,
  getEducations,
  globalFilters,
  setGlobalFilters,
}) => {
  const lang = useContext(langContext);
  const [onlineOnly, setOnlineOnly] = useState(false);
  const {
    Items,
    Languages,
    Faculties,
    Locations,
  } = list;

  const [
    filters,
    setFilters,
    renderCollection,
  ] = useFilters(
    globalFilters,
    setGlobalFilters,
  );

  useEffect(() => {
    if (loaded) {
      if (window.location.search.includes('herning')) {
        setGlobalFilters({ ...globalFilters, locations: [{ ID: 110, Name: 'Herning' }] });
      }
    }
  }, [loaded]);

  let filteredItemsWithOnlineCheck = filteredItems || [];
  if (onlineOnly) {
    filteredItemsWithOnlineCheck = filteredItemsWithOnlineCheck.filter((item) => !!item.Online);
  }

  const renderEducations = filteredItemsWithOnlineCheck.filter((item) => item.Parent === 0).map((education) => (
    <a key={education.ID} href={education.Uri}>
      <div className="bachelor__education">
        {
          !!education.Online && (
            <span className="bachelor__education__note">{education.Online}</span>
          )
        }
        {
          education.Note && (
            <span className="bachelor__education__note">{education.Note}</span>
          )
        }
        <h4 className="bachelor__education__header">{education.Name}</h4>
        {
          education.Children.length > 0 && (
            <>
              <h5>{labels.childrenHeader[lang]}</h5>
              <ul className="bachelor__education__children">
                {education.Children.map((child) => <li key={child.ID}>{child.Name}</li>)}
              </ul>
            </>
          )
        }
        <div className="bachelor__education__info">
          <dl className="bachelor__education__info__group">
            <dt>{`${labels.filters.location[lang]}:`}</dt>
            <dd>{education.Location}</dd>
          </dl>
          <dl className="bachelor__education__info__group">
            <dt>{`${labels.filters.language[lang]}:`}</dt>
            <dd>{education.Language}</dd>
          </dl>
          <dl className="bachelor__education__info__group">
            <dt>{`${labels.filters.faculty[lang]}:`}</dt>
            <dd>{education.Faculty}</dd>
          </dl>
        </div>
      </div>
    </a>
  ));

  const mastersMode = window.endpoint.includes('masters');

  return (
    <AUSpinnerComponent
      loaded={loaded}
      loadingCondition={!loaded}
      height="10rem"
      onLoad={() => getEducations(lang)}
    >
      <AUBachelorSearch items={Items} />
      <div className="bachelor__education__filter__container">
        <span>{`${labels.addFilter[lang]}:`}</span>
        <AUBachelorFilter
          visible={filters.languages}
          selected={globalFilters.languages.length > 0}
          show={() => {
            setFilters({
              languages: true,
              locations: false,
              faculties: false,
              letters: false,
            });
          }}
          hide={() => {
            setFilters(emptyFilter);
          }}
          clear={() => {
            setGlobalFilters({ ...globalFilters, languages: [] });
          }}
          filterName={globalFilters.languages[0]?.Name || labels.filters.language[lang]}
          filterLabel={`${labels.select[lang]} ${labels.filters.language[lang].toLowerCase()}`}
          renderItems={renderCollection(Languages, 'languages')}
        />
        <AUBachelorFilter
          visible={filters.locations}
          selected={globalFilters.locations.length > 0}
          show={() => {
            setFilters({
              languages: false,
              locations: true,
              faculties: false,
              letters: false,
            });
          }}
          hide={() => {
            setFilters(emptyFilter);
          }}
          clear={() => {
            setGlobalFilters({ ...globalFilters, locations: [] });
          }}
          filterName={globalFilters.locations[0]?.Name || labels.filters.location[lang]}
          filterLabel={`${labels.select[lang]} ${labels.filters.location[lang].toLowerCase()}`}
          renderItems={renderCollection(Locations, 'locations')}
        />
        <AUBachelorFilter
          visible={filters.faculties}
          selected={globalFilters.faculties.length > 0}
          show={() => {
            setFilters({
              languages: false,
              locations: false,
              faculties: true,
              letters: false,
            });
          }}
          hide={() => {
            setFilters(emptyFilter);
          }}
          clear={() => {
            setGlobalFilters({ ...globalFilters, faculties: [] });
          }}
          filterName={globalFilters.faculties[0]?.Name || labels.filters.faculty[lang]}
          filterLabel={`${labels.select[lang]} ${labels.filters.faculty[lang].toLowerCase()}`}
          renderItems={renderCollection(Faculties, 'faculties')}
        />
        <div className="bachelor__education__filter">
          <button
            type="button"
            className={`bachelor__education__filter__button${onlineOnly ? ' bachelor__education__filter__button--selected' : ''}`}
            onClick={() => setOnlineOnly(!onlineOnly)}
          >
            {labels.filters.online[lang]}
          </button>
        </div>
        <span>{labels.filters.sorting[lang]}</span>
        <AUBachelorFilter
          right
          visible={filters.letters}
          selected={false}
          show={() => {
            setFilters({
              languages: false,
              locations: false,
              faculties: false,
              letters: true,
            });
          }}
          hide={() => {
            setFilters(emptyFilter);
          }}
          clear={() => {
            setGlobalFilters({ ...globalFilters, letters: [letters[0]] });
          }}
          filterName={globalFilters.letters[0]?.Name}
          filterLabel={`${labels.select[lang]} ${labels.filters.sorting[lang].toLowerCase()}`}
          renderItems={renderCollection(letters, 'letters')}
        />
      </div>
      <AUBachelorAlphabet
        items={Items.filter((item) => item.Parent === 0)}
        selected={globalFilters.alphabet}
        setAlphabet={(letter) => {
          setGlobalFilters({ ...globalFilters, alphabet: letter });
        }}
      />
      {
        filteredItemsWithOnlineCheck.filter((item) => item.Parent === 0).length > 0 && (
          <div className="bachelor__education__container">
            {renderEducations}
          </div>
        )
      }
      {
        filteredItemsWithOnlineCheck.filter((item) => item.Parent === 0).length === 0 && (
          <div className="bachelor__education__no-results">
            <div className="bachelor__education__no-results__text">{mastersMode ? labels.noResultsMasters[lang] : labels.noResults[lang]}</div>
          </div>
        )
      }
    </AUSpinnerComponent>
  );
};

AUBachelorList.propTypes = {
  loaded: PropTypes.bool.isRequired,
  list: PropTypes.shape({
    Locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    Languages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    Faculties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    Items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  filteredItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getEducations: PropTypes.func.isRequired,
  globalFilters: PropTypes.shape({
    languages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    faculties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    letters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    alphabet: PropTypes.string.isRequired,
  }).isRequired,
  setGlobalFilters: PropTypes.func.isRequired,
};

AUBachelorList.displayName = 'AUBachelorList';

export default connect(
  (state) => state.bachelor,
  (dispatch) => bindActionCreators(actionCreators, dispatch),
)(AUBachelorList);
