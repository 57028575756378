/* eslint react/no-danger: 0 */
import React, { useContext } from 'react';
import { modalContentForYouContext } from '../helpers/hooks';

const AUBachelorJourneyModalForYou = () => {
  const content = useContext(modalContentForYouContext);
  return (
    <div className="modal-view__body">
      <div className="bachelor__journey" dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

AUBachelorJourneyModalForYou.displayName = 'AUBachelorJourneyModalForYou';
export default AUBachelorJourneyModalForYou;
