/* eslint-env browser */
import {
  GET_ITEM,
  GET_EDUCATIONS,
  SET_MODAL,
  SET_MODAL_FILTERS,
} from './const';

const actionCreators = {
  getEducations: (lang = 'da') => async (dispatch) => {
    const getData = async () => {
      const url = `${window.endpoint}/getlist?lang=${lang}`;
      const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
      });
      const result = await response.json();
      dispatch({ type: GET_EDUCATIONS, result });
    };

    getData();
  },
  getItem: (id) => async (dispatch) => {
    const getData = async () => {
      const url = `${window.endpoint}/getitem?id=${id}`;
      const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
      });
      const result = await response.json();
      dispatch({ type: GET_ITEM, result });
    };

    getData();
  },
  setModal: (education) => (dispatch) => {
    dispatch({
      type: SET_MODAL,
      result: education,
    });
  },
  setGlobalFilters: (filters) => (dispatch) => {
    dispatch({
      type: SET_MODAL_FILTERS,
      result: filters,
    });
  },
};

export default actionCreators;
