/* eslint-env browser */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AUModalComponent from '@aarhus-university/au-lib-react-components/src/components/delphinus/AUModalComponent';
import AUSpinnerComponent from '@aarhus-university/au-lib-react-components/src/components/delphinus/AUSpinnerComponent';
import { useModal } from '@aarhus-university/au-lib-react-components/src/components/delphinus/hooks';
import AUBachelorSearch from './AUBachelorSearch';
import AUBachelorModal from './AUBachelorModal';
import AUBachelorJourney from './AUBachelorJourney';
import actionCreators from '../actions/bachelor';
import { labels } from '../i18n';
import { langContext } from '../helpers/hooks';

const subjectMap = {
  90: 'media',
  91: 'politics',
  92: 'business-economics',
  93: 'biology',
  94: 'physics',
  95: 'medicine',
  96: 'language',
  97: 'psychology',
  98: 'arts',
  99: 'it',
  100: 'construction',
  101: 'business-communication',
  111: 'media',
  112: 'politics',
  113: 'business-economics',
  114: 'biology',
  115: 'physics',
  116: 'medicine',
  117: 'language',
  118: 'psychology',
  119: 'arts',
  120: 'it',
  121: 'construction',
  122: 'business-communication',
  1068: 'philosophy',
  1069: 'philosophy',
};

const AUBachelorFront = ({
  loaded,
  list,
  modal,
  getEducations,
  setModal,
}) => {
  const lang = useContext(langContext);
  const [interestsModal, setInterestsModal] = useModal('interests-modal', false);

  const {
    Interests,
    Locations,
    Languages,
    Items,
  } = list;

  const renderInterests = Interests.map(
    (interest, i) => (
      <div key={interest.ID} className={`bachelor__interests__subject bachelor__interests__subject--${subjectMap[interest.ID]}`}>
        <button
          type="button"
          id={`interests-btn-${i}`}
          aria-haspopup
          aria-expanded={false}
          onClick={() => {
            setInterestsModal({
              modal: true,
              sender: document.getElementById(`interests-btn-${i}`),
            });
            setModal(interest);
          }}
        >
          {interest.Name}
        </button>
      </div>
    ),
  );
  return (
    <>
      <AUBachelorJourney />
      <AUSpinnerComponent
        loaded={loaded}
        loadingCondition={!loaded}
        height="32rem"
        onLoad={() => getEducations(lang)}
      >
        <h2 className="bachelor__header">{labels.bachelorHeader[lang]}</h2>
        <div className="bachelor__interests">
          {renderInterests}
          <div className="bachelor__link-and-search-container">
            <a href={window.baOverviewUri || '/'} className="button bg-magenta bachelor__education__link-to-all bachelor__education__link-to-all--centered">{labels.linkToAll[lang]}</a>
            <AUBachelorSearch items={Items} centered />
          </div>
        </div>
        <AUModalComponent
          domId="interests-modal"
          sender={interestsModal.sender}
          onClose={() => {
            setInterestsModal({
              modal: false,
              sender: null,
            });
          }}
        >
          <AUBachelorModal
            interest={subjectMap[modal?.ID]}
            languages={Languages}
            locations={Locations}
          />
        </AUModalComponent>
      </AUSpinnerComponent>
    </>
  );
};

AUBachelorFront.propTypes = {
  loaded: PropTypes.bool.isRequired,
  list: PropTypes.shape({
    Interests: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    Locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    Faculties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    Languages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    Items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  modal: PropTypes.shape({

  }).isRequired,
  getEducations: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
};

AUBachelorFront.displayName = 'AUBachelorFront';

export default connect(
  (state) => state.bachelor,
  (dispatch) => bindActionCreators(actionCreators, dispatch),
)(AUBachelorFront);
