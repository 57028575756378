/* eslint-env browser */
import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from '../actions/bachelor';
import { labels } from '../i18n';
import { langContext } from '../helpers/hooks';

const renderPair = ({ key, value }, index) => {
  if (key) {
    return (
      <span>
        <strong>{key}</strong>
        {': '}
        {`${value}`}
        {
          (index % 2 !== 0) && (
            <>
              {' | '}
            </>
          )
        }
        {
          (index % 2 === 0) && <br />
        }
      </span>
    );
  }

  return null;
};

renderPair.propTypes = {
  key: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const AUBachelorInfoBox = ({
  id, langLink, item, getItem,
}) => {
  const lang = useContext(langContext);

  useEffect(() => {
    getItem(id);
  }, []);

  if (!item) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: '#b2e2f6',
        padding: '0 1rem 1rem 1rem',
        overflow: 'auto',
        marginBottom: '1rem',
      }}
    >
      <h4>{labels[lang].header}</h4>
      <div style={{ marginTop: '1rem ' }}>
        {
          item.Kvote1 && (
            <span>
              <strong>{`${labels[lang].quota1} ${window.baInfoBoxYear}`}</strong>
              {': '}
              {`${item.Kvote1}`}
              {
                item.Standby && (
                  <span>
                    {' ('}
                    <strong>{labels[lang].standby}</strong>
                    {': '}
                    {`${item.Standby}`}
                    {')'}
                  </span>
                )
              }
              {' | '}
            </span>
          )
        }
        {
          item.Kvote2 && (
            <span>
              <strong>{`${labels[lang].quota2} ${window.baInfoBoxYear}`}</strong>
              {': '}
              {`${item.Kvote2}`}
            </span>
          )
        }
        {
          (item.Kvote1 && item.Kvote2) && (
            <>
              <br />
              <span>
                <strong><a href={`${window.location.href.replace(window.location.hash, '')}${langLink}`}>{labels[lang].language}</a></strong>
                {' | '}
              </span>
            </>
          )
        }
        {
          Array.from(Array(5).keys()).map((k) => renderPair({ key: item[`ExtraKey${k + 1}`], value: item[`ExtraValue${k + 1}`] }, k + 1))
        }
        <span>
          <strong>{labels[lang].location}</strong>
          {': '}
          {`${item.Location}`}
          {' | '}
        </span>
        <span>
          <strong>{labels[lang].start}</strong>
          {': '}
          {`${item.Start}`}
        </span>
      </div>
    </div>
  );
};

AUBachelorInfoBox.propTypes = {
  id: PropTypes.number.isRequired,
  langLink: PropTypes.string.isRequired,
  item: PropTypes.shape({
    Kvote1: PropTypes.string.isRequired,
    Kvote2: PropTypes.string.isRequired,
    Standby: PropTypes.string.isRequired,
    Location: PropTypes.string.isRequired,
    Start: PropTypes.string.isRequired,
  }).isRequired,
  getItem: PropTypes.func.isRequired,
};

AUBachelorInfoBox.displayName = 'AUBachelorInfoBox';
export default connect(
  (state) => state.bachelor,
  (dispatch) => bindActionCreators(actionCreators, dispatch),
)(AUBachelorInfoBox);
