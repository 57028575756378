const labels = {
  bachelorHeader: {
    da: 'Hvad interesserer du dig for?',
    en: 'What are your interests?',
  },
  linkToAll: {
    da: 'Se alle bacheloruddannelser',
    en: 'See all bachelor\'s programmes',
  },
  svg: {
    forYou: {
      da: 'Er AU noget for dig?',
      en: 'Why choose AU?',
    },
    doubt: {
      da: 'I tvivl om dit studievalg?',
      en: 'Unsure about your<tspan x="0" y="22">choice of programme?</tspan>',
    },
    admission: {
      da: 'Sådan søger <tspan x="0" y="22">du optagelse</tspan>',
      en: 'How to apply <tspan x="0" y="22">for admission</tspan>',
    },
    nowWhat: {
      da: 'Du har søgt ind <tspan x="0" y="22">– hvad nu?</tspan>',
      en: 'You have applied <tspan x="0" y="22">– now what?</tspan>',
    },
    mobile: {
      forYou: {
        da: 'Er AU noget for dig?',
        en: 'Why choose AU?',
      },
      doubt: {
        da: 'I tvivl om dit studievalg?',
        en: 'Unsure about your<tspan x="0" y="22">choice of programme?</tspan>',
      },
      admission: {
        da: 'Sådan søger du optagelse</tspan>',
        en: 'How to apply for admission',
      },
      nowWhat: {
        da: 'Du har søgt ind – hvad nu?</tspan>',
        en: 'You have applied <tspan x="0" y="22">– now what?</tspan>',
      },
    },
  },
  filters: {
    location: {
      da: 'By',
      en: 'Location',
    },
    language: {
      da: 'Sprog',
      en: 'Language',
    },
    faculty: {
      da: 'Fakultet',
      en: 'Faculty',
    },
    online: {
      da: 'Vis kun online-uddannelser',
      en: 'Show only online programmes',
    },
    sorting: {
      da: 'Sortering',
      en: 'Sorting',
    },
  },
  addFilter: {
    da: 'Tilføj filter',
    en: 'Add filter',
  },
  select: {
    da: 'Vælg',
    en: 'Select',
  },
  noResults: {
    da: 'Der findes desværre ingen bacheloruddannelser, der matcher de valgte filtre.',
    en: 'Unfortunately, there are no bachelor\'s programmes that match the selected filters.',
  },
  noResultsMasters: {
    da: 'Der findes desværre ingen kandidatuddannelser, der matcher de valgte filtre.',
    en: 'Unfortunately, there are no master\'s programmes that match the selected filters.',
  },
  searchHeader: {
    da: 'Søg efter bacheloruddannelse',
    en: 'Search for bachelor\'s programme',
  },
  searchHeaderMasters: {
    da: 'Søg efter kandidatuddannelse',
    en: 'Search for master\'s programme',
  },
  childrenHeader: {
    da: 'Linjer',
    en: 'Lines',
  },
  da: {
    header: 'Fakta om uddannelsen',
    quota1: 'Kvote 1',
    quota2: 'Kvote 2',
    standby: 'Standby',
    language: 'Undervisningssprog',
    location: 'Studiested',
    start: 'Studiestart',
  },
  en: {
    header: 'About the programme',
    quota1: 'Quota 1',
    quota2: 'Quota 2',
    standby: 'Standby',
    language: 'Language',
    location: 'Place of study',
    start: 'Commencement',
  },
};

const letters = [
  {
    ID: 'asc',
    Name: 'A-Z',
  }, {
    ID: 'desc',
    Name: 'Z-A',
  }];

export {
  labels,
  letters,
};
