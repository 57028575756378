import React from 'react';
import PropTypes from 'prop-types';

const alphabet = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'æ', 'ø', 'å'];

const AUBachelorAlphabet = ({ items, selected, setAlphabet }) => {
  const renderAlphabet = alphabet.map((letter) => {
    let className = 'bachelor__education__alphabet__letter';
    const inactive = items.filter((x) => x.Name.toLowerCase().indexOf(letter) === 0).length === 0;
    if (inactive) {
      className = `${className} bachelor__education__alphabet__letter--inactive`;
    }
    if (selected === letter) {
      className = `${className} bachelor__education__alphabet__letter--selected`;
    }
    return (
      <li key={letter} className={className}>
        <button
          type="button"
          onClick={() => {
            if (!inactive) {
              setAlphabet(letter);
            }
          }}
        >
          {letter}
        </button>
      </li>
    );
  });

  return (
    <ol className="bachelor__education__alphabet resetlist">
      <li className={`bachelor__education__alphabet__letter${selected === '' ? ' bachelor__education__alphabet__letter--selected' : ''}`}>
        <button
          type="button"
          onClick={() => {
            setAlphabet('');
          }}
        >
          ...
        </button>
      </li>
      {renderAlphabet}
    </ol>
  );
};

AUBachelorAlphabet.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selected: PropTypes.string.isRequired,
  setAlphabet: PropTypes.func.isRequired,
};

AUBachelorAlphabet.displayName = 'AUBachelorAlphabet';
export default AUBachelorAlphabet;
