/* eslint-env browser */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import AUBachelorFront from './components/AUBachelorFront';
import AUBachelorList from './components/AUBachelorList';
import AUBachelorInfoBox from './components/AUBachelorInfoBox';
import AUBachelorJourney from './components/AUBachelorJourney';
import './style/style.scss';

// Get the application-wide store instance,
// prepopulating with state from the server where available.
const initialState = window.initialReduxState;
const store = configureStore(initialState);

const rootElementFront = document.querySelector('.ba-container');
const lang = document.querySelector('html').getAttribute('lang');

if (rootElementFront) {
  ReactDOM.render(
    <Provider store={store}>
      {
        lang === 'da' && (
          <AUBachelorFront />
        )
      }
      {
        lang !== 'da' && (
          <AUBachelorJourney />
        )
      }
    </Provider>,
    rootElementFront,
  );
}

const rootElementList = document.querySelector('.ba-container-list');

if (rootElementList) {
  ReactDOM.render(
    <Provider store={store}>
      <AUBachelorList />
    </Provider>,
    rootElementList,
  );
}

const rootElementSingle = document.querySelector('.ba-info-box');

if (rootElementSingle) {
  const dataId = rootElementSingle.getAttribute('data-id');
  const dataLang = rootElementSingle.getAttribute('data-lang');

  ReactDOM.render(
    <Provider store={store}>
      <AUBachelorInfoBox id={dataId} langLink={dataLang} />
    </Provider>,
    rootElementSingle,
  );
}
