/* eslint-env browser */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect } from 'react';
import AUModalComponent from '@aarhus-university/au-lib-react-components/src/components/delphinus/AUModalComponent';
import { useModal } from '@aarhus-university/au-lib-react-components/src/components/delphinus/hooks';
import AUBachelorJourneySVG from './AUBachelorJourneySVG';
import AUBachelorJourneyModalForYou from './AUBachelorJourneyModalForYou';
import AUBachelorJourneyModalNowWhat from './AUBachelorJourneyModalNowWhat';
import AUBachelorJourneyModalAdmission from './AUBachelorJourneyModalAdmission';
import AUBachelorJourneyModalDoubt from './AUBachelorJourneyModalDoubt';

const AUBachelorJourney = () => {
  useEffect(() => {
    const firstRow = document.querySelector('header~.row:not(.breadcrumb)');
    if (firstRow) {
      firstRow.classList.add('page');
    }
  }, []);

  const [forYouModal, setForYouModal] = useModal('for-you-modal', false);
  const [doubtModal, setDoubtModal] = useModal('doubt-modal', false);
  const [admissionModal, setAdmissionModal] = useModal('admission-modal', false);
  const [nowWhatModal, setNowWhatModal] = useModal('now-what-modal', false);
  return (
    <>
      <AUBachelorJourneySVG
        handleForYou={(ref) => {
          setForYouModal({
            modal: true,
            sender: ref,
          });
        }}
        handleDoubt={(ref) => {
          setDoubtModal({
            modal: true,
            sender: ref,
          });
        }}
        handleAdmission={(ref) => {
          setAdmissionModal({
            modal: true,
            sender: ref,
          });
        }}
        handleNowWhat={(ref) => {
          setNowWhatModal({
            modal: true,
            sender: ref,
          });
        }}
      />
      <AUModalComponent
        domId="for-you-modal"
        className="modal-view modal-view--small"
        sender={forYouModal.sender}
        onClose={() => {
          setForYouModal({
            modal: false,
            sender: null,
          });
        }}
      >
        <AUBachelorJourneyModalForYou />
      </AUModalComponent>
      <AUModalComponent
        domId="doubt-modal"
        className="modal-view modal-view--small"
        sender={doubtModal.sender}
        onClose={() => {
          setDoubtModal({
            modal: false,
            sender: null,
          });
        }}
      >
        <AUBachelorJourneyModalDoubt />
      </AUModalComponent>
      <AUModalComponent
        domId="admission-modal"
        className="modal-view modal-view--small"
        sender={admissionModal.sender}
        onClose={() => {
          setAdmissionModal({
            modal: false,
            sender: null,
          });
        }}
      >
        <AUBachelorJourneyModalAdmission />
      </AUModalComponent>
      <AUModalComponent
        domId="now-what-modal"
        className="modal-view modal-view--small"
        sender={nowWhatModal.sender}
        onClose={() => {
          setNowWhatModal({
            modal: false,
            sender: null,
          });
        }}
      >
        <AUBachelorJourneyModalNowWhat />
      </AUModalComponent>
    </>
  );
};

AUBachelorJourney.displayName = 'AUBachelorJourney';
export default AUBachelorJourney;
